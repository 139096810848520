<template>
  <div class="solutionDetails-home">
    <div class="solutionDetails-content">
      <div class="info-main-left-content">
        <el-card :body-style="{ height: '75vh', overflow: 'auto' }" class="box-card">
          <div slot="header" class="clearfix card-between">
            <div>
              <el-button
                v-if="isEdit"
                type="primary"
                size="mini"
                class="commonBtn"
                icon="el-icon-edit"
                @click="editClick"
              >
                编辑
              </el-button>
              <div v-else>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  class="commonBtn"
                  @click="closeClick"
                >
                  取消
                </el-button>
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-edit"
                  class="commonBtn"
                  @click="saveClick"
                >
                  保存
                </el-button>
              </div>
            </div>
          </div>
          <div class="info-content info-content-main">
            <div class="content-left">
              <div class="info-label">
                <div class="info-title">方案类型：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.typeCategory || info.typeName">
                    {{ info.typeCategory + '-' + info.typeName }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-cascader
                    class="input_item"
                    v-model="typeValue"
                    :options="options"
                    :props="{ expandTrigger: 'hover' }"
                    @change="handleChange"
                  ></el-cascader>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">主诉：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.chiefComplaint">
                    {{ info.chiefComplaint }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    v-model="solutionInfo.chiefComplaint"
                  ></el-input>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">合并症：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.complication">
                    {{ info.complication }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    v-model="solutionInfo.complication"
                  ></el-input>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">症状体征：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.firstDiagnosisSymptomSign">
                    {{ info.firstDiagnosisSymptomSign }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    v-model="solutionInfo.firstDiagnosisSymptomSign"
                  ></el-input>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">方案详情：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.title">
                    {{ info.title }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    v-model="solutionInfo.title"
                  ></el-input>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">治疗方案制定时间：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.solutionTime">
                    {{ info.solutionTime }}
                  </el-tag>
                </div>
                <div v-else>
                  <date-panel
                    class="input_item"
                    ref="datePanel"
                    v-model="solutionInfo.solutionTime"
                    type="date"
                    align="center"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                  ></date-panel>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">首诊时间：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.firstDiagnosisTime">
                    {{ info.firstDiagnosisTime }}
                  </el-tag>
                </div>
                <div v-else>
                  <date-panel
                    class="input_item"
                    ref="datePanel"
                    v-model="solutionInfo.firstDiagnosisTime"
                    type="date"
                    align="center"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                  ></date-panel>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">首诊分期：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.firstDiagnosisLevel">
                    {{ info.firstDiagnosisLevel }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-input
                    class="input_item"
                    type="text"
                    placeholder="请输入"
                    v-model="solutionInfo.firstDiagnosisLevel"
                  ></el-input>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">首次诊断方式：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.firstDiagnosisWay">
                    {{ info.firstDiagnosisWay }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-input
                    class="input_item"
                    type="text"
                    placeholder="请输入"
                    v-model="solutionInfo.firstDiagnosisWay"
                  ></el-input>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">有无病理诊断：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.hasPathologicDiagnosis">
                    {{ info.hasPathologicDiagnosis }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-radio-group
                    v-model="solutionInfo.hasPathologicDiagnosis"
                    style="margin: 5px 0 5px 0"
                  >
                    <el-radio label="有">有</el-radio>
                    <el-radio label="无">无</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div v-if="solutionInfo.hasPathologicDiagnosis == '有'" class="info-label">
                <div class="info-title">病理诊断时间：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.pathologicDiagnosisDate">
                    {{ info.pathologicDiagnosisDate }}
                  </el-tag>
                </div>
                <div v-else>
                  <date-panel
                    class="input_item"
                    ref="datePanel"
                    v-model="solutionInfo.pathologicDiagnosisDate"
                    type="datetime"
                    align="center"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                  ></date-panel>
                </div>
              </div>
              <div v-if="solutionInfo.hasPathologicDiagnosis == '有'" class="info-label">
                <div class="info-title">病理诊断结果：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.pathologicDiagnosisResult">
                    {{ info.pathologicDiagnosisResult }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    v-model="solutionInfo.pathologicDiagnosisResult"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="content-right">
              <div class="info-label">
                <div class="info-title">姓名：</div>
                <el-tag type="info" v-show="info.patientsName">
                  {{ info.patientsName }}
                </el-tag>
              </div>
              <div class="info-label">
                <div class="info-title">病案号：</div>
                <el-tag type="info" v-show="info.patientsID">
                  {{ info.patientsID }}
                </el-tag>
              </div>
              <div class="info-label">
                <div class="info-title">身份证号：</div>
                <el-tag type="info" v-show="info.patientsCertificate">
                  {{ info.patientsCertificate }}
                </el-tag>
              </div>
              <div class="info-label">
                <div class="info-title">年龄：</div>
                <div>
                  <el-tag type="info" v-if="solution?.ageDays">
                    {{ calculateAgeFromDays(solution.ageDays) }}
                  </el-tag>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">身高：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.patientHeight">
                    {{ info.patientHeight + 'cm' }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-input
                    class="input_item"
                    placeholder="请输入"
                    type="text"
                    v-model="solutionInfo.patientHeight"
                    size="mini"
                  >
                    <template slot="append">cm</template>
                  </el-input>
                </div>
              </div>
              <div class="info-label">
                <div class="info-title">体重：</div>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="info.patientWeight">
                    {{ info.patientWeight + 'kg' }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-input
                    class="input_item"
                    placeholder="请输入"
                    type="text"
                    v-model="solutionInfo.patientWeight"
                    size="mini"
                  >
                    <template slot="append">kg</template>
                  </el-input>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import deepClone from '@/plugins/clone'
import DatePanel from '../../../../Menu/components/date-picker/index'
import { calculateAgeFromDays } from '../../../../../utils'

export default {
  name: 'solutionDetails',
  components: {
    DatePanel
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isEdit: true,
      loading: true,
      trendData: [],
      trendLabel: [],
      oprationTableData: [],
      followTableData: [],
      typeValue: [],
      solutionInfo: null,
      solutionNotice: null,
      options: [],
      solution: null
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val) {
          this.isEdit = true
          let arr = []
          this.solutionInfo = deepClone(val)
          arr.push(this.solutionInfo.typeCategory)
          arr.push(this.solutionInfo.typeName)
          this.typeValue = arr
          this.getSolutionType()
          this.getInfo()
        }
      },
      immediate: true
    }
  },
  created() {
    this.isEdit = this.$route.query.isEdit ? false : true
  },
  mounted() {},
  methods: {
    calculateAgeFromDays,
    editClick() {
      this.isEdit = false
    },
    // 这个地方没有返回年龄需要调一下这个接口
    getInfo() {
      this.$api
        .get(`/v1/webconsole/patientCenter/patient/${this.info.patientsIndex}`)
        .then((res) => {
          this.solution = res.data.data
        })
    },
    saveClick() {
      let data = {
        id: this.info.id,
        ...this.solutionInfo
      }

      this.$api.post(`/v1/webconsole/solution/content/save/${this.info.id}`, data).then(() => {
        this.$bus.$emit('getPlanList')
        this.isEdit = true
        return this.$message.success('保存成功')
      })
    },
    closeClick() {
      this.solutionInfo = deepClone(this.info)
      this.isEdit = true
    },
    handleChange() {
      this.solutionInfo.typeCategory = this.typeValue[0]
      this.solutionInfo.typeName = this.typeValue[1]
    },
    getSolutionType() {
      this.$api
        .get(`/v1/webconsole/solution/disease/solutionType/${this.info.diseaseModule}`)
        .then((res) => {
          if (res.data && res.data.data) {
            const cascaderData = {}
            // 遍历原始数组，按照 typeCategory 进行分组
            res.data.data.forEach((item) => {
              if (!cascaderData[item.typeCategory]) {
                cascaderData[item.typeCategory] = []
              }
              cascaderData[item.typeCategory].push({
                label: item.typeName,
                value: item.typeName
              })
            })
            // 将分组后的数据转换为级联选择框需要的格式
            const cascaderOptions = Object.keys(cascaderData).map((category) => ({
              label: category,
              value: category,
              children: cascaderData[category]
            }))
            this.options = cascaderOptions
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.solutionDetails-home {
  .crt-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .solutionDetails-content::-webkit-scrollbar {
    height: 12px;
    width: 10px;
  }
  .solutionDetails-content::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  .solutionDetails-content::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
  }
  .solutionDetails-content::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  .solutionDetails-content::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  .solutionDetails-content {
    flex: 1;
    overflow: auto;
  }

  .info-main-left-content {
    height: calc(50% - 12px);
    margin-bottom: 10px;
  }
}
.el-card {
  margin-bottom: 10px;
  border: none;
}
.el-card.is-always-shadow {
  box-shadow: none;
}
.box-card {
  /deep/.el-card__header {
    padding: 10px 20px 5px;
  }
  .clearfix {
    font-weight: bold;
    font-size: 18px;
  }
  .card-border-none {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
  .card-between {
    display: flex;
    justify-content: space-between;
  }
  .info-content {
    display: flex;

    .content-left,
    .content-right {
      flex: 1;
      overflow: auto;
    }

    .info-label {
      display: flex;
      align-items: center;
      margin: 10px 0;
      font-size: 16px;
      .el-input {
        width: 500px;
      }
      .el-cascader {
        width: 320px;
      }
      .el-textarea {
        width: 450px;
      }
      .info-title {
        min-width: 150px;
      }

      .input_item {
        width: 230px !important;
      }

      .el-textarea {
        width: 600px !important;
      }
    }
  }
  .info-footer {
    position: absolute;
    bottom: 10px;
    right: 20px;
    text-align: right;
    width: calc(100% - 30px);
  }
  .info-echart {
    width: 100%;
    .echart {
      width: 100%;
      height: 290px;
    }
  }
}
.info-main-header {
  min-height: 51px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  .info-main-label {
    color: red;
    padding-left: 20px;
    font-size: 16px;
  }
  .info-main-text {
    height: 30px;
    line-height: 30px;
    color: red;
    padding-left: 20px;
    font-size: 16px;
  }
}
.info-main {
  display: flex;
  height: calc(100% - 62px);
}
.info-main-left {
  width: 50%;
  height: 100%;
  .card-pd {
    height: calc(100% - 15px);
    padding: 15px 20px 0;
    .info-main-left-content {
      height: calc(50% - 12px);
      margin-bottom: 10px;
    }
  }
  .el-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    /deep/.el-card__body {
      flex: 1;
      overflow: auto;
      position: relative;
    }
  }
}
.info-main-right {
  width: 50%;
  height: 100%;
  .card-pd {
    height: calc(100% - 15px);
    padding: 15px 10px 0 0;
    .info-main-right-content {
      height: calc(33% - 9px);
      margin-bottom: 10px;
    }
  }
  .el-card {
    height: 100%;
  }
}

.medical {
  .el-card__body {
    .medical-content {
      width: 100%;
      height: 200px;
    }
  }
}

.input_item {
  margin: 5px 0;
  width: 200px;
}

.el-tag {
  white-space: normal;
  height: auto;
}
</style>
